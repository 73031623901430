// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

// TODO: re-add Stimulus
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Sortable from "stimulus-sortable"

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// https://stimulus-components.netlify.app/docs/components/stimulus-sortable/
application.register("sortable", Sortable)
