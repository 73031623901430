import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['inventoryItemInput', 'inventoryStrategyInputGroup']
  static values  = { dailyInventoryItemIds: Array }

  connect() {
    this.toggleInventoryStrategyInput();
  }

  toggleInventoryStrategyInput() {
    var anyDailyInventoryItemSelected = false;

    this.inventoryItemInputTargets.forEach(inventoryItemInput => {
      if (this.dailyInventoryItemIdsValue.includes(inventoryItemInput.value)) {
        anyDailyInventoryItemSelected = true;
      };
    });

    if (anyDailyInventoryItemSelected) {
      this.inventoryStrategyInputGroupTarget.classList.add('hidden');
    } else {
      this.inventoryStrategyInputGroupTarget.classList.remove('hidden');
    }
  }
}
