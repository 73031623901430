import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['root']
  }

  connect() {
    if (!this.data.has('nextCityId') || !this.data.get('nextCityId')|| !this.hasRootTarget) {
      return
    }

    this.rootTarget.classList.add('price-results--loading')

    fetch(
      location.pathname,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          city_id: this.data.get('nextCityId'),
          update_all: 1,
          [document.querySelector('meta[name="csrf-param"]').getAttribute('content')]: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        })
      }
    )
      .then(r => r.text())
      .then((text) => {
        const html = document.createElement('html')
        html.innerHTML = text
        var newResults = html.querySelector('[data-controller="price-results"]')
        if (this.rootTarget.nextElementSibling) {
          this.rootTarget.parentNode.insertBefore(newResults, this.rootTarget.nextElementSibling)
        } else {
          this.rootTarget.parentNode.appendChild(newResults)
        }
        this.rootTarget.classList.remove('price-results--loading')
      })
  }
}
