import { Controller } from 'stimulus';
import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

export default class extends Controller {
  static targets = ['noteText'];

  connect() {
    loadProgressBar();
  }

  handleEditNote() {
    event.preventDefault();
    this.cancelEditNote();
    var noteId = event.currentTarget.dataset.id;
    var text = this.noteTextTarget.innerHTML.trim();
    var html = '<form method="put" action="/ledger/notes/' + noteId + '" data-remote="true"><textarea name="body">' + text + '</textarea> \
      <br/><button data-action="click->notes#cancelEditNote" type="button" class="btn small">Cancel</button> \
      <button class="btn btn-primary small">Update</button>';
    this.noteTextTarget.innerHTML = html;
    this.cancelEditNote.bind(this);
  }

  cancelEditNote() {
    var text = this.noteTextTarget.dataset.body;
    this.noteTextTarget.innerHTML = text;
  }


}
