import { Controller } from 'stimulus';

const DEFAULT_ERROR = 'There was an error with the merge request. Please try again or contact tech support.';

export default class extends Controller {

  static targets = ["form", "errorMsgContainer", "errorMsg", "successMsg", "cancelBtn", "closeBtn", "submitBtn", "mergeTo", "mergeFrom"]

  connect() {
    // rails dispatches jquery events instead of default JS events,
    // so we're hooking into the global ajax events
    $(document).ajaxSend((event, xhr, options) => {
      if (options.url.indexOf('/merge') == -1) {
        return
      }
      this.resetModal();
      this.disableSubmit();
    });

    $(document).ajaxSuccess((event, xhr, options) => {
      if (options.url.indexOf('/merge') == -1) {
        return
      }
      if (xhr.status === 200) {
        this.checkResponse(JSON.parse(xhr.responseText))
      } else {
        this.renderError();
      }
    });

    $(document).ajaxError((event, xhr, options) => {
      if (options.url.indexOf('/merge') == -1) {
        return
      }
      this.renderError();
      this.enableSubmit();
    });

    // hide errors, etc when modal is closed
    $('#modalMergeGuests').on('hidden.bs.modal', (event) => {
      this.resetModal();
    });
  }

  /**
   * Parses the xhr response for error messages
   * Example: 'The guest could not be found'
   * @param  {json} data - The response payload
   */
  checkResponse(data) {
    const errors = data.errors;

    if (errors) {
      this.renderError(errors);
      this.enableSubmit();
    } else {
      this.updateModalButtons();
      this.renderSuccess();
    }
  }

  validate(e) {
    const mergeToEmail = this.mergeToTarget.value;
    const mergeFromEmail = this.mergeFromTarget.value;

    if (mergeToEmail === mergeFromEmail) {
      e.preventDefault();
      const errorMsg = `Guests cannot match. Unable to merge ${mergeToEmail} into ${mergeFromEmail}.`;
      this.renderError(errorMsg);
    }
  }

  updateModalButtons() {
    this.submitBtnTarget.classList.add('hide');
    this.cancelBtnTarget.classList.add('hide');
    this.closeBtnTarget.classList.remove('hide');
  }

  resetModal() {
    this.errorMsgTarget.textContent = '';
    this.errorMsgContainerTarget.classList.add('hide');
    this.successMsgTarget.classList.add('hide');
  }

  enableSubmit() {
    this.cancelBtnTarget.classList.remove('hide');
    this.submitBtnTarget.textContent = 'Merge';
    this.submitBtnTarget.disabled = false;
  }

  disableSubmit() {
    this.cancelBtnTarget.classList.add('hide');
    this.submitBtnTarget.textContent = 'Merging...';
    this.submitBtnTarget.disabled = true;
  }

  reload() {
    location.reload();
  }

  renderSuccess() {
    this.successMsgTarget.classList.remove('hide');
  }

  renderError(msg = DEFAULT_ERROR) {
    this.errorMsgTarget.textContent = msg;
    this.errorMsgContainerTarget.classList.remove('hide');
  }
}
