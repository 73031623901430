import { Controller } from 'stimulus';

export default class extends Controller {
  static get targets() {
    return ['button', 'citySelector']
  }

  connect() {
    if (this.hasCitySelectorTarget) {
      this.citySelectorTarget.style.display = 'none'
      document.querySelector('[name="update_all"]').addEventListener('change', (e) => {
        this.citySelectorTarget.style.display = e.target.checked ? 'none' : 'block'
      })
    }
  }

  // This next load happens to be a slow synchronous call, so provide some
  // immediate visual feedback
  submit() {
    if (this.hasButtonTarget) {
      this.buttonTarget.setAttribute('disabled', true)
      this.buttonTarget.innerText = 'Loading…'
    }
  }
}
