import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fulfilledQuantityInput', 'exceptionInputs']
  static values  = { requestedQuantity: Number }

  connect() {
    this.toggleExceptionInputs();
  }

  toggleExceptionInputs() {
    if (this.requestedQuantityValue == this.fulfilledQuantityInputTarget.value) {
      this.exceptionInputsTarget.classList.add('hidden');
    } else {
      this.exceptionInputsTarget.classList.remove('hidden');
    }
  }
}
