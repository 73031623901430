import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['value', 'form']

  connect() {
    this.showValue();
  }

  showValue() {
    this.formTarget.classList.add('hidden');
    this.valueTarget.classList.remove('hidden');
  }

  showForm() {
    this.valueTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');
  }
}
