import { Controller } from 'stimulus';
import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

export default class extends Controller {
  static targets = ['markAsSelect','assignmentsCabinCheckBox','tasksCheckBox','newAssignmentForm','reassignSelect','reassignForm','markAsCheckBox','markAsForm','stageCabinKeeperSelect','bulkAssignForm','prepCabinKeeperSelect','actionModal','formatField','cityDropDown','listPanel','actionPanel','caFilterForm','cabinCheckBox', 'sortField','actionButton']

  connect() {
    loadProgressBar();
  }

  checkAllCabins() {
    this.cabinCheckBoxTargets.forEach((mt, index) =>  {
      mt.checked = event.target.checked;
    });
    this.checkAction();
  }

  search() {
    this.formatFieldTarget.value = 'html';
    this.caFilterFormTarget.submit();
  }

  sort() {
    this.formatFieldTarget.value = 'html';
    var sortDirection = 'desc';
    if(this.sortFieldTarget.value.indexOf(' desc') > 0) {
      sortDirection = 'asc';
    }
    this.sortFieldTarget.value = event.currentTarget.dataset.field + ' ' + sortDirection;
    this.caFilterFormTarget.submit();
  }

  checkAction() {
    var enabled =  this.cabinCheckBoxTargets.filter(checkbox => checkbox.checked).length > 0;
    this.actionButtonTargets.forEach((a, index) =>  {
      a.disabled = !enabled;
    });
  }

  toggleSendTexts() {
    var assignmentIds = [];
    this.cabinCheckBoxTargets.filter(checkbox => checkbox.checked).map((cb) => {
      var ids = cb.dataset.assignmentIds.split(',')
      assignmentIds = assignmentIds.concat(ids);
    });
    var city_id = this.cityDropDownTarget.value;
    axios.post('/ledger/staging_assignments/preview_texts', {
      assignment_ids: assignmentIds,
      city_id: city_id,
      redirect_to: location.href
    })
    .then((response) => {
      this.actionModalTarget.innerHTML = response.data;
      this.showActionModal();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  toggleBulkAssign() {
    var cabinIds = [];
    var error = '';
    this.cabinCheckBoxTargets.filter(checkbox => checkbox.checked).map((cb) => {
      if(cb.dataset.status != 'Unstaged') {
        error = 'You cannot bulk re-assign cabins that have already been Prepped and/or Staged.';
      }
      cabinIds.push(cb.value);
    });
    if(error.length > 1) {
      alert(error);
      return;
    }
    var city_id = this.cityDropDownTarget.value;
    axios.post('/ledger/staging_assignments/preview_bulk_assign', {
      cabin_ids: cabinIds,
      city_id: city_id,
      search: location.search
    })
    .then((response) => {
      this.actionModalTarget.innerHTML = response.data;
      this.showActionModal();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  validateBulkAssign() {
    event.preventDefault();
    if(this.prepCabinKeeperSelectTarget.value == '' && this.stageCabinKeeperSelectTarget.value == '') {
      alert('Please select a prep or staging keeper');
      return false;
    }
    this.bulkAssignFormTarget.submit();
  }

  toggleMarkAs() {
    var assignmentIds = [];
    var cabinIds = [];
    this.cabinCheckBoxTargets.filter(checkbox => checkbox.checked).map((cb) => {
      var ids = cb.dataset.assignmentIds.split(',')
      assignmentIds = assignmentIds.concat(ids);
      cabinIds.push(cb.value);
    });
    var city_id = this.cityDropDownTarget.value;
    axios.post('/ledger/staging_assignments/preview_mark_as', {
      assignment_ids: assignmentIds,
      city_id: city_id,
      search: location.search,
      cabin_ids: cabinIds
    })
    .then((response) => {
      this.actionModalTarget.innerHTML = response.data;
      this.showActionModal();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  validateMarkAs() {
    event.preventDefault();
    if(this.markAsSelectTarget.value == '') {
      alert('Please select a status');
      return false;
    }
    this.markAsFormTarget.submit();
  }

  toggleReAssign() {
    event.preventDefault();
    var cabinId = event.currentTarget.dataset.cabinId;
    var taskId = event.currentTarget.dataset.taskId;
    var cabinKeeperId = event.currentTarget.dataset.keeperId;
    var city_id = this.cityDropDownTarget.value;
    var assignmentId = event.currentTarget.dataset.assignmentId;
    var page = event.currentTarget.dataset.page;

    axios.post('/ledger/staging_assignments/reassign', {
      cabin_id: cabinId,
      task_id: taskId,
      current_keeper_id: cabinKeeperId,
      city_id: city_id,
      assignment_id: assignmentId,
      page: page
    })
    .then((response) => {
      this.actionModalTarget.innerHTML = response.data;
      this.showActionModal();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  validateReassign() {
    event.preventDefault();
    if(this.reassignSelectTarget.value == '') {
      alert('Please select a cabin keeper');
      return false;
    }
    this.reassignFormTarget.submit();
  }

  toggleNewAssignment() {
    event.preventDefault();
    var cabinKeeperId = event.currentTarget.dataset.keeperId;
    var city_id = this.cityDropDownTarget.value;

    axios.get('/ledger/staging_assignments/new?keeper_id=' + cabinKeeperId + '&city_id=' + city_id)
    .then((response) => {
      this.actionModalTarget.innerHTML = response.data;
      this.showActionModal();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  updateKeeper() {
    var assignmentId = event.currentTarget.dataset.assignmentId;
    var taskId = event.currentTarget.dataset.taskId;
    var newCabinKeeperId = event.currentTarget.value;
    var cabinId = event.currentTarget.dataset.cabinId
    var city_id = this.cityDropDownTarget.value;

    var obj = event.currentTarget;
    $(obj).parent().addClass('blink');

    if(assignmentId) {
      axios.put('/ledger/staging_assignments/' + assignmentId, {
        cabin_keeper: newCabinKeeperId,
        city_id: city_id,
        format: 'js'
      })
      .then((response) => {
        $(obj).closest('tr').replaceWith(response.data)
        })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      axios.post('/ledger/staging_assignments', {
        cabin_keeper: newCabinKeeperId,
        format: 'js',
        city_id: city_id,
        cabins: [cabinId],
        tasks: [taskId],
        keeper_id: newCabinKeeperId
      })
      .then((response) => {
        $(obj).closest('tr').replaceWith(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });

    }
  }

  validateNewTasks() {
    event.preventDefault();
    if(this.assignmentsCabinCheckBoxTargets.filter(checkbox => checkbox.checked).length == 0) {
      alert('Select at least one cabin');
      return false;
    }

    if(this.tasksCheckBoxTargets.filter(checkbox => checkbox.checked).length == 0){
      alert('Select at least one task');
      return false;
    }
    this.newAssignmentFormTarget.submit();
  }

  collapse() {
    event.preventDefault();
    var id = event.currentTarget.dataset.rowId;
    $(".assignment-rows-" + id).toggle();
    if($(event.currentTarget).find('i').hasClass('fa-chevron-down')) {
     $(event.currentTarget).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-right');
    } else {
     $(event.currentTarget).find('i').removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }
  }

  showHistory() {
    event.preventDefault();
    var historyWindow = window.open(event.currentTarget.href,'historyWindow','width=900,height=600,scrollbars=1');
    historyWindow.focus();
  }

  showActionModal() {
    $("#actionModal").modal();
  }

  export() {
    this.formatFieldTarget.value = 'csv';
    this.caFilterFormTarget.submit();
  }

}
