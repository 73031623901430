import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['phonetype', 'masstexting', 'textmessage', 'masstexting_checkbox', 'textform']

  sendTexts() {
    if(this.textmessageTarget.value.length < 1) {
      alert('Please enter a message');
      return false;
    }
    if(this.masstexting_checkboxTargets.filter(checkbox => checkbox.checked).length < 1) {
      alert('Please enter at least one guest');
      return false;
    }
    if(confirm('Are you sure you want to send this message to the selected guests?')) {
     this.textformTarget.submit();
    }
  }

  checkAll() {
    this.masstexting_checkboxTargets.forEach((mt, index) =>  {
      mt.checked = event.target.checked;
    });
  }

  togglePhone() {
    if(event.target.value == 'MassCalling') {
      this.phonetypeTarget.classList.remove('hidden');
    } else {
      this.phonetypeTarget.classList.add('hidden');
    }
  }

}
