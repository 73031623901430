import { Controller } from "stimulus";
import axios from "axios";
import tippy, { hideAll } from "tippy.js";
import "tippy.js/dist/tippy.css";
export default class extends Controller {
  static targets = [];

  connect() {
    $(document).on("click", '[data-close="popup"]', function () {
      hideAll();
    });

    tippy('[data-toggle="tippy-dropdown"]', {
      allowHTML: true,
      appendTo: document.body,
      content(reference) {
        const templateId = reference.getAttribute("data-template");
        const template = document.getElementById(templateId);
        return template ? template.innerHTML : "";
      },
      interactive: true,
      trigger: "click",
      duration: 100,
      hideOnClick: "toggle",
      offset: [0, -2],
      maxWidth: "none",
      onShow: (instance) => {
        hideAll();
        this.populatePopup(instance);
      },
    });

    $("#assignGuestModal").on("show.bs.modal", function (event) {
      let start = $(event.relatedTarget).data("start");
      let cityId = $(event.relatedTarget).data("city-id");
      let houseId = $(event.relatedTarget).data("house-id");
      let familyName = $(event.relatedTarget).data("family-name");

      axios
        .get(
          `/ledger/house_diary/unassigned_guests?start=${start}&family_name=${familyName}&city_id=${cityId}&house_id=${houseId}`
        )
        .then((response) => {
          cleanDropdown("#assign-guest");
          loadGuestsDropdown(response);
          disableSubmit();
        })
        .catch(function (error) {
          console.log(error);
        });

      let modal = $(this);
      modal.find("#house_id").val(houseId);
    });

    /**
     * Whenever the "All" option input checkbox is changed to
     * checked/unchecked the rest of family options are updated
     * in consequence
     */
    $(".familyFilterAll").on("change", function (event) {
      if (event.target.checked) {
        $(".familyFilter").prop("checked", true);
      } else {
        $(".familyFilter").prop("checked", false);
      }
    });

    /**
     * Whenever a family input checkbox is changed to
     * unchecked it unchecks the "All" option
     */
    $(".familyFilter").on("change", function (event) {
      if (!event.target.checked) {
        $(".familyFilterAll").prop("checked", false);
      }
    });

    /**
     * When cabins dropdown change detects if a valid cabin
     * value was selected and in consequence enable or disable
     * submit form button
     */
    $("#assigned-cabin").on("change", function (event) {
      enableDisableSubmitBtn(event);
    });

    /**
     * When guests dropdown change detects if a valid cabin
     * value was selected and in consequence enable or disable
     * submit form button
     */
    $("#assign-guest").on("change", function (event) {
      enableDisableSubmitBtn(event);
    });

    $("#assignCabinModal").on("show.bs.modal", function (event) {
      let reservationId = $(event.relatedTarget).data("reservation");
      $("#assigned-cabin").children().remove("optgroup");
      cleanDropdown("#assigned-cabin");

      axios
        .get(
          `/ledger/house_diary/unassigned_cabins?reservation_id=${reservationId}`
        )
        .then((response) => {
          loadCabinsDropdown(response);

          $("#assigned-cabin").select2({
            width: "100%",
            dropdownParent: $("#assignCabinModal"),
            templateResult: function (state) {
              if (!state.loading) {
                //return state.text
                if (
                  !state.element.dataset.accessible ||
                  state.element.dataset.accessible === "false"
                ) {
                  return state.text;
                } else {
                  let $state = $(
                    "<span>" +
                      state.text +
                      ' <i class="fa fa-wheelchair-alt"></i></span>'
                  );
                  return $state;
                }
              }
              return state.text;
            },
          });
          //this.showActionModal();
        })
        .catch(function (error) {
          console.log(error);
        });

      let guestName = $(event.relatedTarget).data("name");
      let modal = $(this);

      modal.find(".modal-guest-name").text(guestName);
      modal.find("#reservation_id").val(reservationId);
      modal
        .find("#assign_cabin_form")
        .attr("action", "/ledger/house_diary/assign_cabin/" + reservationId);
    });

    /**
     * When opens edit Service Entry modal, it fills form with the
     * right data
     */
    $("#unassignCabinModal").on("show.bs.modal", function (event) {
      let $target = $(event.relatedTarget);
      let guestName = $target.data("name");
      let cabinName = $target.data("house");
      let modal = $(this);
      modal.find(".modal-guest-name").text(guestName);
      modal.find(".modal-cabin-name").text(cabinName);

      let reservationId = $(event.relatedTarget).data("reservation");

      modal.find("#reservation_id").val(reservationId);
      modal
        .find("#unassign_cabin")
        .attr("action", "/ledger/house_diary/unassign_cabin/" + reservationId);
    });

    /**
     * When opens new Service Entry model loads and clean initialized form
     */
    $("#createServiceEntryModal").on("show.bs.modal", function (event) {
      if (event.relatedTarget) {
        let modal = $(this);
        initNewServiceEntryForm(event, modal);
        disableSubmit();
      }
    });

    /**
     * When opens edit Service Entry modal, it fills form with the
     * right data
     */
    $("#editServiceEntryModal").on("show.bs.modal", function (event) {
      if (event.relatedTarget) {
        let serviceEntryId = $(event.relatedTarget).data("service");
        let start = $(event.relatedTarget).data("start");
        let end = $(event.relatedTarget).data("end");
        let house = $(event.relatedTarget).data("house");
        let houseId = $(event.relatedTarget).data("houseid");
        let reasonCode = $(event.relatedTarget).data("reasoncode");
        let notes = $(event.relatedTarget).data("notes");
        let modal = $(this);

        let startField = modal.find('[name="start"]');
        let finishField = modal.find('[name="finish"]');

        modal.find("#overlaping").addClass("hide");
        modal.find('[name="service_entry_id"]').val(serviceEntryId);
        startField.val(start);
        finishField.val(end);
        modal.find('[name="reason_code"]').val(reasonCode);
        modal.find('[name="notes"]').val(notes);
        modal.find('[name="house"]').val(house);
        modal.find('[name="house_id"]').val(houseId);
        modal
          .find("#remove-service-entry")
          .attr("data-service-entry", serviceEntryId);
        modal
          .find("#edit_service_entry")
          .attr(
            "action",
            "/ledger/house_diary/edit_service_entry/" + serviceEntryId
          );

        var dateToday = new Date();
        var dateTodayToLocale = dateToday
          .toLocaleString("en-US", {
            timeZone: "America/New_York",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
        var endDate = new Date(end);
        endDate.setDate(endDate.getDate() + 1); //need to do this because end date is end of day, not start of day
        var endDateToLocale = endDate
          .toLocaleString("en-US", {
            timeZone: "America/New_York",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");

        if (dateTodayToLocale > endDateToLocale) {
          modal.find("#dateLockCheck").prop("checked", true);
          startField.prop("disabled", "disabled");
          finishField.prop("disabled", "disabled");
        }

        // update datepicker fields
        updateDate(startField, start);
        updateDate(finishField, end);
      }
    });

    /**
     * When opens modal fills it with information
     */
    $("#removeServiceEntryModal").on("show.bs.modal", function (event) {
      let $target = $(event.relatedTarget);
      let link = $target.data("link");
      let cabinName = $target.data("house");
      let modal = $(this);

      modal.find(".modal-cabin-name").text(cabinName);
      modal.find("#remove_service_entry").attr("action", link);
    });

    /**
     * When opens modal fills it with information
     */
    $("#serviceEntryHistoryModal").on("show.bs.modal", function (event) {
      let $target = $(event.relatedTarget);
      $.ajax({
        url: "/ledger/service_entries/" + $target.data("service") + "/history",
        type: "GET",
        dataType: "js",
        data: {},
        complete: function (data) {
          $("#history-entries").html(data.responseText);
        },
      });
    });

    /**
     * When click empty Book block it fills attributes
     * for links in popup
     */
    $(".book-empty-block").on("click", function () {
      let start = $(this).attr("data-start");
      let cityId = $(this).attr("data-city-id");
      let houseId = $(this).attr("data-house-id");
      let beds = $(this).attr("data-beds");
      let familyName = $(this).attr("data-family-name");

      $(".link-assign-guest").attr("data-start", start);
      $(".link-assign-guest").attr("data-city-id", cityId);
      $(".link-assign-guest").attr("data-house-id", houseId);
      $(".link-assign-guest").attr("data-beds", beds);
      $(".link-assign-guest").attr("data-family-name", familyName);
    });

    $("#assign-guest").on("change", function () {
      let reservationId = $(this).val();

      $(this)
        .closest("form")
        .attr(
          "action",
          "/ledger/house_diary/assign_guest_to_cabin/" + reservationId
        );
    });

    /**
     * When click on input chekbox for allow/deny overlaping
     * between a service entry and a reservation examines that
     * checkbox is checked or not
     *
     * Result : enable or disable submit button in form
     */
    $(".form-check-input").on("click", function (event) {
      let submitButton = event.target
        .closest("form")
        .querySelector('input[type="submit"]');

      if (event.target.checked) {
        submitButton.removeAttribute("disabled");
      } else {
        submitButton.setAttribute("disabled", "disabled");
      }
    });

    /**
     * Whenever a lock date input checkbox is unchecked
     * it enables start and finish dates and when it is checked
     * it disables start and finish dates
     */
    $("#dateLockCheck").on("change", function (event) {
      var lockStatus = event.target.checked;
      $(".start").prop("disabled", lockStatus);
      $(".finish").prop("disabled", lockStatus);
    });

    // Service Entry Modal: Check range dates
    // Start should be smaller than End
    $(".start").on("change", function (event) {
      let { form, houseId, submitButton, parentDiv, errorSpan } =
        setServiceEntryVariables(event);
      let endDate = form.querySelector("input.finish").value;
      let startDate = event.target.value;

      let validRange =
        endDate !== ""
          ? rangeDates(endDate, startDate, parentDiv, errorSpan)
          : false;
      let validReason = checkForReason(event);

      checkForOverlaping(
        form,
        houseId,
        startDate,
        endDate,
        validRange,
        validReason,
        submitButton
      );
    });

    // Service Entry Modal: Check range dates
    // Start should be smaller than End
    $(".finish").on("change", function (event) {
      let { form, houseId, submitButton, parentDiv, errorSpan } =
        setServiceEntryVariables(event);
      let startDate = form.querySelector("input.start").value;
      let endDate = event.target.value;

      let validRange = rangeDates(endDate, startDate, parentDiv, errorSpan);
      let validReason = checkForReason(event);
      checkForOverlaping(
        form,
        houseId,
        startDate,
        endDate,
        validRange,
        validReason,
        submitButton
      );
    });

    /**
     * At each keyup on "notes" input this runs and check for good range dates
     * and existent notes/reasons to determine valid value.
     *
     * Important: in case the reason is empty on backend this field is saved as
     * "No reason provided"
     */
    $(".notes").on("keyup", function (event) {
      let { form, houseId, submitButton, parentDiv, errorSpan } =
        setServiceEntryVariables(event);
      let startDate = form.querySelector("input.start").value;
      let endDate = form.querySelector("input.finish").value;
      let notes = event.target.value;
      let validRange = rangeDates(endDate, startDate, parentDiv, errorSpan);

      if (notes !== undefined && notes !== "") {
        parentDiv.removeClass("has-error");
        errorSpan.addClass("hide");
      } else {
        parentDiv.addClass("has-error");
        errorSpan.removeClass("hide");
      }

      checkForOverlaping(
        form,
        houseId,
        startDate,
        endDate,
        validRange,
        notes !== undefined && notes !== "",
        submitButton
      );
    });

    /**
     * At each change on "reason_code" select this runs and check for good range dates
     * and existent reasons to determine valid value.
     *
     */
    $("#reason_code").on("change", function (event) {
      let { form, houseId, submitButton, parentDiv, errorSpan } =
        setServiceEntryVariables(event);
      let startDate = form.querySelector("input.start").value;
      let endDate = form.querySelector("input.finish").value;
      let reasonCode = event.target.value;
      let validRange = rangeDates(endDate, startDate, parentDiv, errorSpan);

      if (reasonCode !== undefined && reasonCode !== "") {
        parentDiv.removeClass("has-error");
        errorSpan.addClass("hide");
      } else {
        parentDiv.addClass("has-error");
        errorSpan.removeClass("hide");
      }

      checkForOverlaping(
        form,
        houseId,
        startDate,
        endDate,
        validRange,
        reasonCode !== undefined && reasonCode !== "",
        submitButton
      );
    });

    /**
     * Enable or disable submit button in form
     *
     * @param {*} event
     */
    function enableDisableSubmitBtn(event) {
      let submitButton = event.target
        .closest("form")
        .querySelector('input[type="submit"]');

      if (event.target.value === "") {
        submitButton.setAttribute("disabled", "disabled");
      } else {
        submitButton.removeAttribute("disabled");
      }
    }

    /**
     * Load guests dropdown selector
     *
     * @param {*} response The response data after query call
     */
    function loadGuestsDropdown(response) {
      let guests = response.data;

      for (var i = 0; i < guests.length; i++) {
        $("#assign-guest").append(
          "<option value=" +
            guests[i].id +
            ">" +
            guests[i].first_name +
            " " +
            guests[i].last_name +
            "</option>"
        );
      }
    }

    /**
     * Disable submit button
     */
    function disableSubmit() {
      let submitButton = $('input[type="submit"]');
      submitButton.attr("disabled", "disabled");
    }

    /**
     * Load cabins dropdown selector
     *
     * @param {*} response The response data after query call
     *
     */
    function loadCabinsDropdown(response) {
      let cabins = response.data;
      let neighborhoods = Object.keys(cabins);

      for (var i = 0; i < neighborhoods.length; i++) {
        let neighborhoodCabins = "";
        neighborhoodCabins += '<optgroup label="' + neighborhoods[i] + '">';

        for (var j = 0; j < cabins[neighborhoods[i]].length; j++) {
          let accessible = cabins[neighborhoods[i]][j][3];

          neighborhoodCabins +=
            "<option data-accessible=" +
            accessible +
            " value=" +
            cabins[neighborhoods[i]][j][0] +
            ">" +
            cabins[neighborhoods[i]][j][1] +
            "</option>";
        }

        neighborhoodCabins += "</optgroup >";
        $("#assigned-cabin").append(neighborhoodCabins);
      }
    }

    /**
     * Initializes the form for create new Service Entry
     *
     * @param {*} event
     * @param {*} modal The modal itself where is filled the form
     */
    function initNewServiceEntryForm(event, modal) {
      let start = $(event.relatedTarget).data("start");
      let house_id = $(event.relatedTarget).data("house-id");

      $("#allow-overlaping").prop("checked", false);
      $("#overlaping").addClass("hide");
      $('input[type="submit"]').attr("disabled", false);
      $(".notes-div").addClass("has-error");

      modal.find('[name="start"]').val(start);
      modal.find('[name="finish"]').val("");
      modal.find('[name="reason_code"]').val("");
      modal.find('[name="notes"]').val("");
      modal.find('[name="house_id"]').val(house_id);

      // update datepicker
      updateDate(modal.find('[name="start"]'), start);
      updateDate(modal.find('[name="finish"]'), "");
    }

    /**
     * Returns variables to fill Service Entry form
     *
     * @param {*} event
     * @returns {form, houseId, submitButton, parentDiv, errorSpan}
     */
    function setServiceEntryVariables(event) {
      let form = event.target.closest("form");
      let houseId = form.querySelector("input#house_id").value;
      let submitButton = event.target
        .closest("form")
        .querySelector('input[type="submit"]');
      let parentDiv = $(event.target).closest("div");
      let errorSpan = $(event.target).next("span");
      return { form, houseId, submitButton, parentDiv, errorSpan };
    }

    /**
     * Removes all options in a select dropdown
     *
     * @param {*} target The node id to clean
     */
    function cleanDropdown(target) {
      $(target).find("option").not(":first").remove();
    }

    /**
     * Checks for correct date range and hides or shows error messages
     *
     * @param {*} endDate The start date range
     * @param {*} startDate The end date range
     * @param {*} parentDiv The previous div entity
     * @param {*} errorSpan The error span entity
     * @param {*} submitButton The submit span entity
     */
    function rangeDates(endDate, startDate, parentDiv, errorSpan) {
      // reset errors
      $(".modal-body").find(".help-block").addClass("hide");
      $(".modal-body").find(".has-error").removeClass("has-error");

      if (endDate !== "" && endDate <= startDate) {
        parentDiv.addClass("has-error");
        errorSpan.removeClass("hide");
        return false;
      } else {
        parentDiv.removeClass("has-error");
        errorSpan.addClass("hide");
        return true;
      }
    }

    /**
     * Check for reason text message present in form
     *
     * @param {*} event
     * @returns true if reasonCode not empty
     * @returns false if reasonCode empty
     */
    function checkForReason(event) {
      let reasonCode = event.target
        .closest("form")
        .querySelector("select#reason_code").value;

      if (reasonCode !== undefined && reasonCode !== "") {
        return true;
      } else {
        return false;
      }
    }

    /**
     * Checks for possible overlapping between Service Entry and Reservation
     *
     * @param {*} houseId
     * @param {*} startDate
     * @param {*} endDate
     * @param {*} validRange
     * @param {*} validReason
     * @param {*} submitButton
     */
    function checkForOverlaping(
      form,
      houseId,
      startDate,
      endDate,
      validRange,
      validReason,
      submitButton
    ) {
      axios
        .get(
          `/ledger/house_diary/reservations?house_id=${houseId}&start=${startDate}&finish=${endDate}`
        )
        .then((response) => {
          const allowOverlaping = form.querySelector(
            'input[id="allow-overlaping"]'
          ).checked;
          let warningMessagePanel = form.querySelector('div[id="overlaping"]');

          if (response.data > 0) {
            // response.data : qty of reservations under same range date
            $(warningMessagePanel).removeClass("hide");

            if (
              allowOverlaping === true &&
              validRange === true &&
              validReason === true
            ) {
              submitButton.removeAttribute("disabled");
            } else {
              submitButton.setAttribute("disabled", "disabled");
            }
          } else {
            $(warningMessagePanel).addClass("hide");

            if (validRange === true && validReason === true) {
              submitButton.removeAttribute("disabled");
            } else {
              submitButton.setAttribute("disabled", "disabled");
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  populatePopup(instance) {
    const fieldTypes = [
      "email",
      "name",
      "reservation",
      "phone",
      "dates",
      "link",
      "start",
      "end",
      "reasontext",
      "notes",
      "service",
      "paidpremium",
      "paidluxe",
      "paidhighmaintenance",
      "donotmove",
      "accesible",
      "vip",
      "repeatguest",
      "warning",
      "house",
      "houseid",
      "diycampsite",
      "allsetcampsite",
      "reason",
      "extendedstay",
    ];
    const dataTypes = [
      "start",
      "end",
      "reasoncode",
      "notes",
      "link",
      "service",
      "name",
      "reservation",
      "house",
      "paidpremium",
      "paidluxe",
      "paidhighmaintenance",
      "donotmove",
      "accesible",
      "vip",
      "repeatguest",
      "warning",
      "house",
      "houseid",
      "diycampsite",
      "allsetcampsite",
      "reason",
      "extendedstay",
    ];
    const popup = instance.popper;
    const reference = instance.reference;
    // add custom data to each popup field
    fieldTypes.forEach((element) => {
      let elem = popup.querySelector('[data-field="' + element + '"]');
      let arr = [
        "paidpremium",
        "paidluxe",
        "paidhighmaintenance",
        "vip",
        "accesible",
        "donotmove",
        "repeatguest",
        "warning",
        "diycampsite",
        "allsetcampsite",
      ];
      if (elem) {
        if (element == "link") {
          elem.href = reference.dataset[element];
        } else if (
          element == "paidpremium" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#128176";
        } else if (
          element == "paidluxe" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#128081";
        } else if (
          element == "paidhighmaintenance" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#128081&#128081";
        } else if (element == "vip" && reference.dataset[element] === "true") {
          elem.innerHTML = "&#x2B50";
        } else if (
          element == "accesible" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = '<i class="fa fa-wheelchair-alt"></i>';
        } else if (
          element == "donotmove" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#128274";
        } else if (
          element == "repeatguest" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#128260";
        } else if (
          element == "warning" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#128680";
        } else if (
          element == "diycampsite" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#127794";
        } else if (
          element == "allsetcampsite" &&
          reference.dataset[element] === "true"
        ) {
          elem.innerHTML = "&#9978";
        } else if (!arr.includes(element)) {
          if (reference.dataset[element] && reference.dataset[element] !== "") {
            elem.textContent = reference.dataset[element];
          }
        }
      }
    });

    // set any custom data attribute values
    dataTypes.forEach((element) => {
      if (popup.querySelector("[data-" + element + "]")) {
        $.each(
          popup.querySelectorAll("[data-" + element + "]"),
          function (_, node) {
            node.dataset[element] = reference.dataset[element];
          }
        );
      }
    });
  }
}
