import { Controller } from 'stimulus';

const adjustmentTypes = {
  REPLACE:  'AddOns::StockItemCountReplacement',
  SUBTRACT: 'AddOns::StockItemCountSubtraction'
}

export default class extends Controller {
  static targets = ['dropdown', 'inputAndSubmit', 'quantityLabel', 'quantityHint', 'submitButton']
  static values  = { city: String, item: String }

  connect() {
    this.displayForm();
  }

  displayForm() {
    switch (this.dropdownTarget.value) {
    case adjustmentTypes.REPLACE:
    case adjustmentTypes.SUBTRACT:
      this.quantityLabelTarget.textContent = this.labelText();
      this.quantityHintTarget.textContent = this.hintText();
      this.submitButtonTarget.setAttribute('data-confirm', this.confirmText());
      this.submitButtonTarget.disabled = false;
      this.inputAndSubmitTarget.classList.remove('hidden');
      break;
    default:
      this.submitButtonTarget.disabled = true;
      this.inputAndSubmitTarget.classList.add('hidden');
    }
  }

  labelText() {
    switch (this.dropdownTarget.value) {
    case adjustmentTypes.REPLACE:
      return 'New inventory quantity';
    case adjustmentTypes.SUBTRACT:
      return 'Quantity to subtract';
    }
  }

  hintText() {
    let city = this.cityValue;
    let item = this.itemValue;

    switch (this.dropdownTarget.value) {
    case adjustmentTypes.REPLACE:
      return `This will replace the ${item} item's total count on hand for ${city}.`
    case adjustmentTypes.SUBTRACT:
      return `This will subtract from the ${item} item's total count on hand for ${city}.`
    }
  }

  confirmText() {
    let city = this.cityValue;
    let item = this.itemValue;

    switch (this.dropdownTarget.value) {
    case adjustmentTypes.REPLACE:
      return `Confirm replacing the ${item} item's total count on hand for ${city}.`
    case adjustmentTypes.SUBTRACT:
      return `Confirm subtracting from the ${item} item's total count on hand for ${city}.`
    }
  }
}
